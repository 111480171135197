<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center" v-if="$store.state.user.user_type != 'super_admin'">
        <i class="fas fa-clipboard-list font-22 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Partner Policy</h1>
</app-header>

<app-footer />

<div class="page-title-clear"></div>
<div class="page-content">
    <div class="content" style="letter-spacing: 1px;line-height: 1cm;">

        <div class="content" style="letter-spacing: 1px;">
            <div class="row">
                <span class="muted font-15">TO MAITAIN HEALTH ACCOUNT STATUS</span>
                <strong class="font-25 primary-light-text mt-3">PARTNER POLICY</strong>
            </div>
        </div>

        <div class="card rounded-s">
            <div class="accordion" id="accordion-1" style="letter-spacing: 1px;">
                <button
                    class="btn btn-ripple accordion-btn px-2 no-effect color-theme collapsed d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false">
                    <i class="fa fa-star primary-alt-base-text me-2"></i>
                    <span class="w-100 primary-alt-base-text buttonTitle">
                        Applicable to all the Lifey Partner registered on Partner Platform
                    </span>
                    <i class="fa fa-chevron-down font-10 accordion-icon text-dark"></i>
                </button>
                <div id="collapse1" class="collapse" data-parent="#accordion-1">
                    <div class="divider mb-2"></div>
                    <div class="pt-1 pb-2 ps-3 pe-3">
                        <p class="description">
                            Partner Policy Document.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>