<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center"
        v-if="$store.state.user.user_type != 'super_admin'">
        <i class="far fa-comment font-20 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">FAQS</h1>
</app-header>

<app-footer />

<div class="page-title-clear"></div>
<div class="page-content">
    <div class="content" style="letter-spacing: 1px;line-height: 1cm;">
        <div class="content" style="letter-spacing: 1px;">
            <div class="row">
                <span class="muted font-15">F.A.Q</span>
                <strong class="font-25 primary-light-text mt-3">FREQUENTLY ASKED QUESTIONS</strong>
            </div>
        </div>

        <div class="card card-style mx-0 mb-2 rounded-s" v-for="(item,index) in faqsData">
            <div class="accordion" :id="`accordian-${index}`">
                <button
                    class="btn btn-ripple accordion-btn px-2 no-effect d-flex align-items-center"
                    data-bs-toggle="collapse" :data-bs-target="`#collapse-${index}`">
                    <!-- <i class="fas fa-question-circle primary-alt-base-text me-2"></i> -->
                    <span class="w-100 primary-alt-base-text buttonTitle ms-1 font-500 letterSpacing">
                        {{ item.title }}
                    </span>
                    <i class="fa fa-chevron-down font-10 accordion-icon text-dark ms-2"></i>
                </button>
                <div :id="`collapse-${index}`" class="collapse" :data-parent="`#accordian-${index}`">
                    <div class="divider mb-3"></div>
                    <div class="pt-1 pb-3 ps-3 pe-3">
                        <p class="description font-500">
                            {{ item.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>