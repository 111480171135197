<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Lifely Partner Program</h1>
    <router-link :to="{name: 'login'}" v-if="!user" class="header-icon header-icon-3 font-15 "
        style="letter-spacing:1px;">
        <strong>Login</strong>
    </router-link>
    <router-link :to="{name: 'dashboard-home'}" v-else class="header-icon header-icon-2 font-15"
        style="letter-spacing:1px;">
        <i class="fas fa-tachometer-alt"></i>
    </router-link>
</app-header>


<div class="page-title-clear"></div>

<!-- language selection chips part -->
<div class="text-center">
    <div class="content mb-0">
        <a href="javascript:void(0)" @click="changeToEnglish" class="chip chip-s bg-gray-light btn-ripple">
            <i class="fa fa-check " :class="[ english ? 'primary-alt-light-bg' : 'bg-grey-dark']"></i>
            <strong class="color-black font-600 letterSpacing">English</strong>
        </a>
        <a href="javascript:void(0)" @click="changeToHindi" class="chip chip-s bg-gray-light btn-ripple">
            <i class="fa fa-check" :class="[ hindi ? 'primary-alt-light-bg' : 'bg-grey-dark']"></i>
            <strong class="color-black font-600 letterSpacing">Hindi</strong>
        </a>
        <a href="javascript:void(0)" @click="changeToGujarati" class="chip chip-s chip-image bg-gray-light btn-ripple">
            <i class="fa fa-check " :class="[ gujarati ? 'primary-alt-light-bg' : 'bg-grey-dark']"></i>
            <strong class="color-black font-600 letterSpacing">Gujarati</strong>
        </a>
    </div>
</div>

<!-- page-content in english lang -->
<div class="page-content" v-if="english" style="letter-spacing:0.5px;">
    <div class="row content g-3">

        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
            <img src="/images/lifely/hero-partner-success.png" class="img-fluid" width="400">
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> Get rewarded for selling and recommending 120 healthiest
                    products of Lifely
                    Brand</h1>
                <p class="font-18"> In addition to the fair selling commisions, below are some of the precious value
                    added services we are humbly providing to our Partners for boosting their business</p>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-atlas fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">Non-Video Kiosk</a>
                        </h3>
                        <p class="font-16 letterSpacing lh-lg">Small Retail shops gets our Non-Video Kiosk with a product pack
                            and tasting
                            kits for their customers</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-tv fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">Video Kiosk</a></h3>
                        <p class="font-16 letterSpacing lh-lg">Big Retailers gets kiosk which has a 12-inch Video screen on
                            top where we
                            broadcast product features with an interactive tablet where consumers can get more
                            information and also shop on our website right from there.</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-child fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800 "><a href="" class="color-theme letterSpacing">Full Body Composition
                                testing
                                machine.</a></h3>
                        <p class="font-16 letterSpacing lh-lg">Generates 75 types of body reports for Customer
                        </p>
                        <div class="d-flex">
                            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1">Know More
                                <i class="fas fa-arrow-right ms-1"></i></a>
                            <a href="https://youtu.be/uU46zkLnQ8Y" class="btn primary-light-bg btn-ripple ms-1">Watch in
                                Action <i class="fas fa-arrow-right ms-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-12 col-sm-12 col-lg-6">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> And We don't stop there...</h1>
                <p class="font-18 letterSpacing lh-lg"> Based on sales performance we offer unique opportunitis & benefits to
                    our hard
                    working partners</p>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i
                            class="fas fa-certificate fa-3x primary-alt-light-text"></i></div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">International
                                certification</a></h3>
                        <p class="font-16 letterSpacing lh-lg">We are affiliating with USA top universities to provide short
                            term courses
                            for Dieticians, Gym owners, and Nutritionists. They will get international certification and
                            will also arrange to attend seminars in USA, UK, and Dubai.</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i
                            class="fas fa-plane-departure primary-alt-light-text fa-3x"></i></div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">Free International
                                tour</a></h3>
                        <p class="font-16 letterSpacing lh-lg">We will be arranging international Lifely Partner meets in
                            Dubai and London
                            and based on sales performance our partners will be given a free tour.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
            <img src="/images/lifely/partner-success.png" class="img-fluid" width="350" height="350">
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="content">
        <div class="row ms-5 g-2" id="tagLine">
            <p class="mb-0 font-18 text-secondary letterSpacing">WHO CAN JOIN US</p>
            <h1 class="font-30 font-500 letterSpacing primary-alt-base-text">WE ARE WELCOMING</h1>
        </div>
        <div class="row justify-content-evenly">
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-store primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Small Retailers</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-store-alt primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Big Retailers</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-md primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Doctors</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-hospital-symbol primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Clinics</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-heartbeat primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Nutritionist/Dieticians</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-dumbbell primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Gym Owners</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-hospital-alt primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Hospitals</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-running primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Health Specialists</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-building primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Super Markets</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-users primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Distributors</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card px-0">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-check primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Sales Consultants</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-tie primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 letterSpacing">Influencers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="content color-theme">
        <div class="row justify-content-evenly text-center">
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-smile fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">120</h3>
                        <p class="card-text font-16">Healthy Products</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-map-marker-alt fa-3x  primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">28</h3>
                        <p class="card-text font-16">States shipping</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-users fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">1,463</h3>
                        <p class="card-text font-16">Partners</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-warehouse fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">6</h3>
                        <p class="card-text font-16">Warehouse</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 text-center">
            <img src="/images/lifely/hero-partner-dietician.png" class="img-fluid" width="340">
        </div>
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> Benefits for Nutritionist</h1>

                <ul style="list-style: none;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg">
                            <i class="fas fa-check  me-2 primary-light-text"></i>
                            Reconnect with your previous clients by showcasing portfolio of 120 healthiest products.
                        </p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Give more
                            value-added
                            services through our products to your new and existing clients.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Provide easy
                            calorie
                            management with our Packaged products</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Maintain
                            long term
                            relation with your clients by suggesting variety of Lifely Products.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Attrack new
                            clients with
                            Lifely brand goals.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Active
                            channel partners to
                            get consistent additional income and value-added services.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Opportunity
                            to attend
                            international level seminars and certification arranged by Lifely.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Advertise
                            your business at
                            multi tier level through our Partner Blog, Health App,and Website</p>
                    </li>
                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">Know More
                <i class="fas fa-arrow-right ms-1"></i></a>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> Benefits for Doctors</h1>

                <ul style="list-style: none;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Medicine
                            effect more if
                            the diet is better through our superfood based ingredients.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>HealthApp
                            can give
                            customized diet, national-level nutritionist consultancy for your patients.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Virtual
                            tie-up like cure
                            fit. If they want.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Addition
                            consistent
                            income.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Mouth
                            advertising is key
                            like a nutritionist.</p>
                    </li>

                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">Know More
                <i class="fas fa-arrow-right ms-1"></i></a>
        </div>
        <div class="col-md-6 col-sm-12 text-center ">
            <img src="/images/lifely/hero-partner-doctor.png" class="img-fluid" width="400" height="275">
        </div>
    </div>
    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 text-center ">
            <img src="/images/lifely/hero-partner-gym-owners.jpg" class="img-fluid" width="338" height="400">
        </div>
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">Benefits for Gym Owners</h1>
                <em class="font-16 letterSpacing">With Dieticians</em>
                <ul style="list-style: none;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Easy to
                            carry and fast
                            consuming healthy options.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Faster
                            results from our
                            Products due to precise content details.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>All-Nutrient
                            Option macro
                            and micro in liquid, powder and many in final food forms.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Reduce
                            dietician workload
                            with only lunch and dinner planning.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Additional
                            income sources.
                        </p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Advertise
                            your business in
                            our Health App and Consumer Blog</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>New
                            customers leads</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i
                                class="fas fa-check  me-2 primary-light-text"></i>International
                            certification course opportunity.</p>
                    </li>
                </ul>
                <em class="font-16 letterSpacing">Without Dieticians</em>
                <ul style="list-style: none;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Get Virtual
                            nutrition
                            mapping.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Body
                            composition scanner
                            option in the future(As Per Sales Target) </p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Major work
                            support as a
                            diet part through Lifely website and our Health consultant.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>More
                            one-o-one
                            publicity,consistent income with better product results.</p>
                    </li>
                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">Know More
                <i class="fas fa-arrow-right ms-1"></i></a>
        </div>
    </div>
</div>

<!-- page-content in hindi lang -->
<div class="page-content" v-if="hindi" style="letter-spacing:0.5px;">
    <div class="row content g-3">

        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
            <img src="/images/lifely/hero-partner-success.png" class="img-fluid" width="400">
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">Lifely Brand के 120 स्वास्थ्यप्रद उत्पादों को बेचने और अनुशंसा
                    करने के लिए
                    पुरस्कार प्राप्त करें</h1>
                <p class="font-18 letterSpacing lh-lg">फेयर सेलिंग कमिशन के अलावा, नीचे कुछ कीमती मूल्य वर्धित सेवाएं दी गई हैं जो हम अपने
                    भागीदारों को उनके व्यवसाय को बढ़ावा देने के लिए विनम्रतापूर्वक प्रदान कर रहे हैं।</p>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-atlas fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">नॉन-वीडियो कियोस्क</a></h3>
                        <p class="font-16 letterSpacing lh-lg">छोटी रिटेल दुकानों को हमारे नॉन-वीडियो कियोस्क के साथ उत्पाद पैक और उनके
                            ग्राहकों के लिए टेस्टिंग किट मिलते हैं</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-tv fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">वीडियो कियोस्क</a></h3>
                        <p class="font-16 letterSpacing lh-lg">बड़े रिटेलर्स को कियोस्क मिलता है जिसमें शीर्ष पर 12 इंच की वीडियो स्क्रीन
                            होती है जहां हम एक इंटरैक्टिव टैबलेट के साथ उत्पाद सुविधाओं को प्रसारित करते हैं जहां
                            उपभोक्ता अधिक जानकारी प्राप्त कर सकते हैं और वहीं से हमारी वेबसाइट पर खरीदारी भी कर सकते
                            हैं।</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-child fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800 "><a href="" class="color-theme letterSpacing">पूर्ण शारीरिक संरचना परीक्षण
                                मशीन।</a></h3>
                        <p class="font-16 letterSpacing lh-lg">ग्राहक के लिए 75 प्रकार की बॉडी रिपोर्ट तैयार करता है
                        </p>
                        <div class="d-flex">
                            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">अधिक
                                जानकारी <i class="fas fa-arrow-right ms-1"></i></a>
                            <a href="https://youtu.be/uU46zkLnQ8Y" class="btn primary-light-bg btn-ripple ms-1 letterSpacing">एक्शन में
                                देखें <i class="fas fa-arrow-right ms-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-12 col-sm-12 col-lg-6">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">और हम यहीं नहीं रुकते...</h1>
                <p class="font-18 letterSpacing lh-lg"> बिक्री के प्रदर्शन के आधार पर हम अपने मेहनती भागीदारों को अद्वितीय अवसर और लाभ
                    प्रदान करते हैं</p>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i
                            class="fas fa-certificate fa-3x primary-alt-light-text"></i></div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">अंतर्राष्ट्रीय प्रमाणीकरण</a></h3>
                        <p class="font-16 letterSpacing lh-lg">हम आहार विशेषज्ञों, जिम मालिकों और पोषण विशेषज्ञों के लिए अल्पकालिक पाठ्यक्रम
                            प्रदान करने के लिए संयुक्त राज्य अमेरिका के शीर्ष विश्वविद्यालयों के साथ संबद्ध हैं। उन्हें
                            अंतरराष्ट्रीय प्रमाणन मिलेगा और वे यूएसए, यूके और दुबई में सेमिनार में भाग लेने की व्यवस्था
                            भी करेंगे।</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i
                            class="fas fa-plane-departure primary-alt-light-text fa-3x"></i></div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">फ्री इंटरनेशनल टूर</a></h3>
                        <p class="font-16 letterSpacing lh-lg">हम दुबई और लंदन में इंटरनेशनल Lifely पार्टनर की बैठक की व्यवस्था करेंगे और
                            बिक्री के प्रदर्शन के आधार पर हमारे भागीदारों को एक मुफ्त टूर दिया जाएगा।</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
            <img src="/images/lifely/partner-success.png" class="img-fluid" width="350" height="350">
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="content">
        <div class="row ms-5 g-2" id="tagLine">
            <p class="mb-0 font-18 text-secondary letterSpacing">कौन हमसे जुड़ सकता है</p>
            <h1 class="font-30 font-500 letterSpacing primary-alt-base-text">हम स्वागत कर रहे हैं</h1>
        </div>
        <div class="row justify-content-evenly">
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-store primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800">छोटे रिटेलर्स</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-store-alt primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800">बड़े रिटेलर्स</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-md primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">डॉक्टरों</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-hospital-symbol  primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">क्लिनिक</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-heartbeat primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">पोषण विशेषज्ञ / आहार विशेषज्ञ</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-dumbbell primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">जिम के मालिक</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-hospital-alt primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">अस्पताल</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-running primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">स्वास्थ्य विशेषज्ञ</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-building primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">सुपर मार्केट</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-users primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">डिस्ट्रिब्यूटर्स</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card px-0">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-check primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800">सेल्स कन्सल्टन्ट</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-tie primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">इन्फ्लुएंसर्स</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="content color-theme">
        <div class="row justify-content-evenly text-center">
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-smile fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">120</h3>
                        <p class="card-text font-16">हेल्दी प्रोडक्ट्स</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-map-marker-alt fa-3x  primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">28</h3>
                        <p class="card-text font-16">राज्य शिपिंग</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-users fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">1,463</h3>
                        <p class="card-text font-16">भागीदारों</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-warehouse fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">6</h3>
                        <p class="card-text font-16">वेयरहाउस</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 text-center">
            <img src="/images/lifely/hero-partner-dietician.png" class="img-fluid" width="340">
        </div>
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">पोषण विशेषज्ञ के लिए लाभ</h1>

                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>120 स्वास्थ्यप्रद उत्पादों
                            के पोर्टफोलियो का प्रदर्शन करके अपने पिछले ग्राहकों के साथ फिर से जुड़ें।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>अपने नए और मौजूदा ग्राहकों
                            को हमारे उत्पादों के माध्यम से अधिक मूल्य वर्धित सेवाएं दें।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>हमारे पैकेज्ड उत्पादों के
                            साथ आसान कैलोरी प्रबंधन प्रदान करें</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely उत्पादों की विविधता
                            का सुझाव देकर अपने ग्राहकों के साथ दीर्घकालिक संबंध बनाए रखें।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely ब्रांड लक्ष्यों के
                            साथ नए ग्राहकों को आकर्षित करें।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>लगातार अतिरिक्त आय और
                            मूल्य वर्धित सेवाएं प्राप्त करने के लिए सक्रिय चैनल भागीदार।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely द्वारा आयोजित
                            अंतरराष्ट्रीय स्तर के सेमिनार और प्रमाणन में भाग लेने का अवसर।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>हमारे पार्टनर ब्लॉग, हेल्थ
                            ऐप और वेबसाइट के माध्यम से अपने व्यवसाय का बहु स्तरीय स्तर पर विज्ञापन दें</p>
                    </li>
                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">अधिक जानकारी <i
                    class="fas fa-arrow-right ms-1"></i></a>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">डॉक्टरों के लिए लाभ</h1>

                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>हमारे सुपरफूड आधारित
                            अवयवों के माध्यम से आहार बेहतर होने पर दवा का प्रभाव अधिक होता है।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>हेल्थ ऐप आपके मरीजों के
                            लिए अनुकूलित आहार, राष्ट्रीय स्तर के पोषण विशेषज्ञ परामर्श दे सकता है।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>क्योरफिट जैसा वर्चुअल
                            टाई-अप। अगर वे चाहते हैं।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>अतिरिक्त लगातार इनकम।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>पोषण विशेषज्ञ की तरह मुंह
                            का विज्ञापन महत्वपूर्ण है।</p>
                    </li>

                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">अधिक जानकारी <i
                    class="fas fa-arrow-right ms-1"></i></a>
        </div>
        <div class="col-md-6 col-sm-12 text-center ">
            <img src="/images/lifely/hero-partner-doctor.png" class="img-fluid" width="400" height="275">
        </div>
    </div>
    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 text-center ">
            <img src="/images/lifely/hero-partner-gym-owners.jpg" class="img-fluid" width="338" height="400">
        </div>
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">जिम मालिकों के लिए लाभ</h1>
                <em class="font-16 letterSpacing">डाइटिशियन के साथ</em>
                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>ले जाने में आसान और तेजी
                            से उपभोग करने वाले स्वस्थ विकल्प।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>सटीक सामग्री विवरण के कारण
                            हमारे उत्पादों से तेज़ परिणाम मिलते हैं।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>ऑल-न्यूट्रिएंट ऑप्शन
                            मैक्रो और माइक्रो इन लिक्विड, पाउडर और कई फाइनल फूड फॉर्म में।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>केवल लंच और डिनर प्लानिंग
                            के साथ डायटीशियन का काम का बोझ कम करें।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>अतिरिक्त इनकम के स्रोत।
                        </p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>हमारे हेल्थ ऐप और उपभोक्ता
                            ब्लॉग में अपने व्यवसाय का विज्ञापन करें</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>नए ग्राहक लीड</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>अंतर्राष्ट्रीय प्रमाणन
                            पाठ्यक्रम का अवसर।</p>
                    </li>
                </ul>
                <em class="font-16 letterSpacing">आहार विशेषज्ञ के बिना</em>
                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>आभासी पोषण मानचित्रण
                            प्राप्त करें।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>भविष्य में बॉडी कंपोजिशन
                            स्कैनर विकल्प (बिक्री लक्ष्य के अनुसार)</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely वेबसाइट और हमारे
                            स्वास्थ्य सलाहकार के माध्यम से आहार के हिस्से के रूप में प्रमुख कार्य समर्थन।</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>अधिक एक-से-एक प्रचार,
                            बेहतर उत्पाद परिणामों के साथ लगातार इनकम।</p>
                    </li>
                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">अधिक जानकारी <i
                    class="fas fa-arrow-right ms-1"></i></a>
        </div>
    </div>
</div>

<!-- page-content in gujarati lang -->
<div class="page-content" v-if="gujarati" style="letter-spacing:0.5px;">
    <div class=" row content g-3">

        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
            <img src="/images/lifely/hero-partner-success.png" class="img-fluid" width="400">
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> Lifely Brand ના 120 આરોગ્યપ્રદ ઉત્પાદનોને વેચવા અને ભલામણ કરવા બદલ પુરસ્કાર
                    મેળવો</h1>
                <p class="font-18 letterSpacing lh-lg"> વાજબી વેચાણ કમિશન ઉપરાંત, નીચે આપેલ ભાગીદારોને તેમના વ્યવસાયને વેગ આપવા નમ્રતાપૂર્વક
                    આપેલા કેટલાક કિંમતી મૂલ્ય વર્ધક સેવાઓ છે.</p>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-atlas fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">નોન-વિડીયો કિઓસ્ક</a></h3>
                        <p class="font-16 letterSpacing lh-lg">નાની છૂટક દુકાન અમારી પ્રોડક્ટ પેક અને તેમના ગ્રાહકો માટે કિટ્સ ટેસ્ટિંગ સાથે
                            નોન-વિડિઓ કિઓસ્ક મેળવે છે</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-tv fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">વિડિઓ કિઓસ્ક</a></h3>
                        <p class="font-16 letterSpacing lh-lg">મોટા રિટેલરોને કિઓસ્ક મળે છે જેની ઉપર 12 ઇંચની વિડિઓ સ્ક્રીન છે જ્યાં અમે
                            ઇન્ટરેક્ટિવ ટેબ્લેટથી ઉત્પાદન સુવિધાઓ પ્રસારિત કરીએ છીએ જ્યાં ગ્રાહકો વધુ માહિતી મેળવી શકે
                            છે અને ત્યાંથી અમારી વેબસાઇટ પર ખરીદી પણ કરી શકે છે.</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i class="fas fa-child fa-3x primary-alt-light-text"></i>
                    </div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800 "><a href="" class="color-theme letterSpacing">સંપૂર્ણ શારીરિક રચના પરીક્ષણ
                                મશીન.</a></h3>
                        <p class="font-16 letterSpacing lh-lg">ગ્રાહક માટે 75 પ્રકારના બોડી રિપોર્ટ્સ ઉત્પન્ન કરે છે
                        </p>
                        <div class="d-flex">
                            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">વધુ જાણો
                                <i class="fas fa-arrow-right ms-1"></i></a>
                            <a href="https://youtu.be/uU46zkLnQ8Y" class="btn primary-light-bg btn-ripple ms-1 letterSpacing">એક્શન જુઓ <i
                                    class="fas fa-arrow-right ms-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-12 col-sm-12 col-lg-6">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> અને અમે ત્યાં અટકતા નથી...</h1>
                <p class="font-18 letterSpacing lh-lg"> વેચાણ પ્રભાવના આધારે અમે અમારા સખત મહેનતુ ભાગીદારોને અનન્ય તકો અને લાભ પ્રદાન કરીએ
                    છીએ</p>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i
                            class="fas fa-certificate fa-3x primary-alt-light-text"></i></div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">આંતરરાષ્ટ્રીય પ્રમાણપત્ર</a></h3>
                        <p class="font-16 letterSpacing lh-lg">ડાયેટિશિયન, જિમના માલિકો અને ન્યુટ્રિશનિસ્ટ્સ માટે ટૂંકા ગાળાના અભ્યાસક્રમો
                            પ્રદાન કરવા માટે અમે યુએસએની ટોચની યુનિવર્સિટીઓ સાથે જોડાણ કરી રહ્યા છીએ. તેઓને
                            આંતરરાષ્ટ્રીય પ્રમાણપત્ર મળશે અને યુએસએ, યુકે અને દુબઇમાં સેમિનારોમાં ભાગ લેવાની પણ વ્યવસ્થા
                            કરશે.</p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-md-3 col-sm-3 col-lg-2 text-center"><i
                            class="fas fa-plane-departure primary-alt-light-text fa-3x"></i></div>
                    <div class="col-md-9 col-sm-9 col-lg-10">
                        <h3 class=" title font-800"><a href="" class="color-theme letterSpacing">મફત આંતરરાષ્ટ્રીય પ્રવાસ</a></h3>
                        <p class="font-16 letterSpacing lh-lg">અમે દુબઇ અને લંડનમાં આંતરરાષ્ટ્રીય Lifely પાર્ટનરની બેઠક ગોઠવીશું અને વેચાણ
                            પ્રભાવના આધારે અમારા ભાગીદારોને મફત પ્રવાસ આપવામાં આવશે.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
            <img src="/images/lifely/partner-success.png" class="img-fluid" width="350" height="350">
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="content">
        <div class="row ms-5 g-2" id="tagLine">
            <p class="mb-0 font-18 text-secondary  letterSpacing">કોણ અમારી સાથે જોડાઈ શકે છે</p>
            <h1 class="font-30 font-500 letterSpacing primary-alt-base-text">અમે તમને આવકારી રહ્યા છીએ</h1>
        </div>
        <div class="row justify-content-evenly">
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-store primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">નાના રિટેલરો</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-store-alt primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">મોટા રિટેલરો</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-md primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">ડોકટરો</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-hospital-symbol  primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">ક્લિનિક્સ</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-heartbeat primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">ન્યુટ્રિશનિસ્ટ / ડાયેટિશિયન</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-dumbbell primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">જિમ માલિકો</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-hospital-alt primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">હોસ્પિટલો</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-running primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">આરોગ્ય નિષ્ણાતો</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-building primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">સુપરમાર્કેટ</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-users primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">વિતરકો</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card px-0">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-check primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800">સેલ્સ સલાહકાર</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body d-flex">
                        <i class="fas fa-user-tie primary-light-text fa-2x me-3"></i>
                        <p class="font-16 font-800 ">ઇન્ફ્લ્યુન્સર્સ</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="content color-theme">
        <div class="row justify-content-evenly text-center">
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-smile fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">120</h3>
                        <p class="card-text font-16">હેલ્ધી પ્રોડક્ટ્સ</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-map-marker-alt fa-3x  primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">28</h3>
                        <p class="card-text font-16">સ્ટેટ્સ શિપિંગ</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-users fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">1,463</h3>
                        <p class="card-text font-16">ભાગીદારો</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-6">
                <div class="card">
                    <div class="card-body m-4">
                        <i class="fas fa-warehouse fa-3x primary-alt-light-text icon-dash"></i>
                        <h3 class="card-title font-30 mt-2 primary-light-text">6</h3>
                        <p class="card-text font-16">વેરહાઉસ</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 text-center">
            <img src="/images/lifely/hero-partner-dietician.png" class="img-fluid" width="340">
        </div>
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                        <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> ન્યુટ્રિશનિસ્ટ માટે ફાયદા</h1>

                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>120 આરોગ્યપ્રદ ઉત્પાદનોના
                            પોર્ટફોલિયોના પ્રદર્શન દ્વારા તમારા પાછલા ગ્રાહકો સાથે ફરીથી જોડાઓ.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>અમારા ઉત્પાદનો દ્વારા
                            તમારા નવા અને અસ્તિત્વમાંના ક્લાયંટ્સને વધુ વેલ્યુએડેડ સેવાઓ આપો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>તમારા પેકેજ્ડ ઉત્પાદનો
                            સાથે સરળ કેલરી મેનેજમેન્ટ પ્રદાન કરો</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely પ્રોડક્ટ્સના વિવિધ
                            સૂચનો દ્વારા તમારા ગ્રાહકો સાથે લાંબા ગાળાના સંબંધ જાળવો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely Brand ગોલવાળા નવા
                            ગ્રાહકોને આકર્ષિત કરો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>સતત વધારાની આવક અને મૂલ્ય
                            વર્ધિત સેવાઓ મેળવવા માટે સક્રિય ચેનલ ભાગીદારો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>લાઇફલી દ્વારા ગોઠવાયેલા
                            આંતરરાષ્ટ્રીય કક્ષાના સેમિનારો અને પ્રમાણપત્રમાં ભાગ લેવાની તક.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>અમારા ભાગીદાર બ્લોગ,
                            આરોગ્ય એપ્લિકેશન અને વેબસાઇટ દ્વારા તમારા વ્યવસાયને મલ્ટિ-ટાયર લેવલે જાહેર કરો</p>
                    </li>
                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">વધુ જાણો <i
                    class="fas fa-arrow-right ms-1"></i></a>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500"> ડોકટરો માટે ફાયદા</h1>

                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>જો આપણા સુપરફૂડ આધારિત
                            ઘટકો દ્વારા આહાર વધુ સારું હોય તો દવાઓની અસર વધુ થાય છે.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>હેલ્થ એપ્લિકેશન તમારા
                            દર્દીઓ માટે કસ્ટમાઇઝ્ડ આહાર, રાષ્ટ્રીય કક્ષાના પોષણ નિષ્ણાતની સલાહ આપી શકે છે.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>વર્ચ્યુઅલ ટાઇ-અપ જેવા કે
                            ક્યુરિટિફિટ. જો તેઓ ઇચ્છે.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>સતત આવક ઉમેરો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>મોં એ જાહેરાતની પોષક
                            નિષ્ણાતની જેમ ચાવી છે.</p>
                    </li>

                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">વધુ જાણો <i
                    class="fas fa-arrow-right ms-1"></i></a>
        </div>
        <div class="col-md-6 col-sm-12 text-center ">
            <img src="/images/lifely/hero-partner-doctor.png" class="img-fluid" width="400" height="275">
        </div>
    </div>
    <div class="mt-5"></div>

    <div class="row content g-3">
        <div class="col-md-6 col-sm-12 text-center ">
            <img src="/images/lifely/hero-partner-gym-owners.jpg" class="img-fluid" width="338" height="400">
        </div>
        <div class="col-md-6 col-sm-12 ">
            <div class="row g-2">
                <h1 class="primary-alt-base-text letterSpacing lh-lg font-30 font-500">જિમ માલિકો માટે ફાયદા</h1>
                <em class="font-16 letterSpacing">ડાયટિશિયન સાથે</em>
                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>વહન કરવા માટે સરળ અને ઝડપી
                            વપરાશમાં લેવાતા તંદુરસ્ત વિકલ્પો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>સચોટ સામગ્રી વિગતોને કારણે
                            અમારા ઉત્પાદનોના ઝડપી પરિણામો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>પ્રવાહી, પાવડર અને ઘણા
                            અંતિમ ખોરાક સ્વરૂપોમાં ઓલ પોષક વિકલ્પ મેક્રો અને માઇક્રો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>માત્ર લંચ અને ડિનર
                            પ્લાનિંગ સાથે ડાયટિશિયન વર્કલોડ ઘટાડવો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>વધારાના આવકના સ્રોત.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>અમારા આરોગ્ય એપ્લિકેશન અને
                            ગ્રાહક બ્લોગમાં તમારા વ્યવસાયની જાહેરાત કરો</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>નવા ગ્રાહકોની લીડ્સ</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>આંતરરાષ્ટ્રીય પ્રમાણપત્ર
                            અભ્યાસક્રમ તક.</p>
                    </li>
                </ul>
                <em class="font-16 letterSpacing">ડાયેટિશિયન વિના</em>
                <ul style="list-style: none;letter-spacing:1px;">
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>વર્ચ્યુઅલ પોષણ મેપિંગ
                            મેળવો.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>ભવિષ્યમાં બોડી કમ્પોઝિશન
                            સ્કેનર વિકલ્પ (વેચાણ લક્ષ્યાંક મુજબ)</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>Lifely વેબસાઇટ અને અમારા
                            આરોગ્ય સલાહકાર દ્વારા આહારના ભાગ રૂપે મુખ્ય કાર્ય સહાયક.</p>
                    </li>
                    <li>
                        <p class="font-16 mb-1 letterSpacing lh-lg"><i class="fas fa-check  me-2 primary-light-text"></i>વધુ એક થી એક પ્રસિદ્ધિ,
                            વધુ સારા ઉત્પાદન પરિણામો સાથે સુસંગત આવક.</p>
                    </li>
                </ul>
            </div>
            <a href="https://tanita.eu/mc-980ma-plus/" class="btn primary-light-bg btn-ripple me-1 letterSpacing">વધુ જાણો <i
                    class="fas fa-arrow-right ms-1"></i></a>
        </div>
    </div>
</div>