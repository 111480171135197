
export default {
    data() {
        return {
            faqsData: [
                {
                    title: 'What is Lifely Partner program?',
                    description: 'Lifely Partner is a unique program which gives our partners a platform to expand their\
                    business by recommending to their customers a wide range of healthy products provided by\
                    Lifely.'
                },
                {
                    title: 'Is Lifely a local, national or global brand?',
                    description: 'Lifely is an Indian brand with a global footprint. We have presence in the UK, Europe,\
                    Dubai and GCC. Soon, Lifely products will be launched in the US, Australia, New Zealand,\
                    Russia, Singapore, Hong Kong, South America and Japan.'
                },
                {
                    title: 'What kind of products are going to be launched now and how many are in the pipeline?',
                    description: 'We are launching 15 products in the segments of ketchup, spreads, breakfast cereals,\
                    tabletop sweeteners and tea (full detail available on the website). Later, we will come\
                    out with 105 more products, including beverages & health drinks, breakfast cereals,\
                    spreads, kids food, ice cream & yogurts, sweets, ketchup, soup, bakery products,\
                    nutritional bars, chocolates and confectionery, to name a few.'
                },
                {
                    title: 'What are the company’s offline and online advertising-awareness drives plans?',
                    description: 'Our massive promotional drive will include health awareness and knowledge campaigns (both\
                        offline and online), exhibitions and seminars, youth concerts, loyalty programs, health\
                        check-ups, brand endorsements through celebrities, influencers, doctors, nutritionists,\
                        health experts, customer surprise campaigns, and Lifely community program.'
                },
                {
                    title: 'How can Lifely help me in my FMCG store business?',
                    description: 'In your business, the biggest challenge is retaining the customer. There is no guarantee\
                    that the same customer will come back to your store for the same product. But our\
                    business model gives you the opportunity to make the customer your permanent client for\
                    Lifely products as those who subscribe to your store code can buy Lifely products\
                    through that code only.'
                },
                {
                    title: 'What is the subscription process and how will a customer become a permanent client?',
                    description: 'To begin with, you will become our retail partner through our digital subscription\
                    process, which will generate unique code for your store. When customers walk in to your\
                    store, you will need to explain to them about Lifely products and convince them to place\
                    a trial order. They will be required to complete a subscription process under your\
                    unique code to place that order. After that, whenever that customer places an order for\
                    any Lifely product anywhere in India it will be counted under your code and you will get\
                    the profit of all those purchases.'
                },
                {
                    title: 'What if a customer subscribes to multiple stores?',
                    description: 'Just one code can be generated from one mobile number. A customer will need different\
                    numbers to subscribe to more stores. Generally, customers don\'t have time to search for\
                    another number unless they have had some bad experience with your store.'
                },
                {
                    title: 'How can I increase the volume of business from your model?',
                    description: 'First, you have to subscribe to as many customers as possible to increase your permanent\
                    customer base comprising both walk-in and non-walk-in clientage, which we call\
                    Horizontal Growth. This is followed by vertical growth to increase the volume of your\
                    business, through which you have to make your customers purchase other products of\
                    Lifely which was not part of the trial order. Most importantly, you can pitch our 105\
                    products to be launched soon to your clients.This way we provide one of the biggest\
                    volume generating business opportunities in the FMCG sector.'
                },
                {
                    title: 'What is your USP that will reduce the pain points of our business?',
                    description: 'You neither have to make any investment nor keep any stock of our products in your store.\
                    Only a little space for a kiosk (where products will be on display with the required\
                    information) is required in your store in order to convince customers how Lifely\
                    products can benefit them and make them super healthy, which will allow them to enjoy a\
                    super life.'
                },
                {
                    title: 'If there is no stock in the store, how do customers get their products?',
                    description: 'You need not worry about product delivery once the order is placed under your code. The\
                    products will be delivered to the customer’s residence within 48 hours of placing the\
                    trial order. For further orders, customers can fix different future dates in our system\
                    based on their consumption cycle. Then they can rest assured as the products will reach\
                    them on the specified dates.'
                },
                {
                    title: 'What responsibilities will we have in your online supply chain system?',
                    description: 'You will have no responsibilities at all. Everything, from warehouse to deliveries, will\
                    be managed by a professional team of our company.'
                },
                {
                    title: 'So, there will be no tension of product return as physical goods are not in our possession?',
                    description: 'True. As our model does not have any product stocking system for stores, return policy\
                    doesn’t come into picture. We completely understand that one of the biggest issues is\
                    managing the return process for food items, which sometimes limits the order planning of\
                    retailers.'
                },
                {
                    title: 'How does a store code generate?',
                    description: 'To generate store codes, our business relationship executives pay personal visits to\
                    stores. Registered partners can generate the code from their account as well.'
                },
                {
                    title: 'What is the process to subscribing customers?',
                    description: 'Partner stores can let customers subscribe to our products by directly sharing a referral\
                    link with them from their Registered Account. Besides, customers can register themselves\
                    by scanning a QR code available at the kiosk inside the store.'
                },
                {
                    title: 'Is there any restriction on which customer can or cannot be offered the subscription?',
                    description: 'There is no such restriction. You can offer the subscription to anybody, be it your\
                    walk-in customers or somebody outside.'
                },
                {
                    title: 'How can I track the purchase pattern of the customer registered under my code?',
                    description: 'It can be tracked on your registered account with us. Real-time purchase updates are made\
                    in the partner\'s registered account. Besides, we send out weekly/monthly email reports\
                    to our partners showing all the purchase patterns.'
                },
                {
                    title: 'What is your payment system for the partners?',
                    description: 'We have a very transparent payment system. A partner can check the commission earned for\
                    every order placed using his code in his registered account. Payment is made on the\
                    first day of each month to the bank account provided by the partner. Partners are also\
                    notified for all the payments processed. All the data related payments, commissions and\
                    orders can be checked on the partner’s registered account 24X7.'
                },
                {
                    title: 'How will the company help us increase our business?',
                    description: 'We already have in place a detailed plan for awareness campaigns focusing not only on\
                    specific local areas but also at the national level to ensure that our partners increase\
                    their horizontal growth.'
                },
                {
                    title: 'What are your plans for vertical growth?',
                    description: 'We have a comprehensive plan for vertical growth. We have roped in a battery of employees\
                    who are qualified nutritionists. Besides, we will soon launch pan-India health services\
                    with an aim to make our clients completely healthy. These are some of the plans outlined\
                    by our team of experts to increase the vertical growth.'
                },
                {
                    title: 'What are the company’s expectations from us?',
                    description: 'We only expect our partners to invest time in selling our products. You should\
                    enthusiastically and sincerely provide information on Lifely products to all the\
                    customers coming to your store. You must make your best efforts to make the customers\
                    subscribe to our products and convince them to place the trial order. You should follow\
                    up if the order is not placed within 24 hours of subscription. Your active participation\
                    in all awareness campaigns is expected, besides maintaining a good relationship with\
                    your customers. You should also focus on increasing your own horizontal and vertical\
                    growth as a Lifely partner.'
                },
                {
                    title: 'What incentives do you give if we perform beyond your expectations?',
                    description: 'We monitor the performance of each partner at regular intervals. Based on their\
                    performance, we dole out certain incentives/benefits to them.'
                },
                {
                    title: 'What happens if the performance is not up to the mark?',
                    description: 'The first and foremost attempt is to identify the problem. Either you can identify the\
                    problem, which we can sort out together or you will have to coordinate with us to\
                    pinpoint the issue, which then can be fixed accordingly.'
                },
                {
                    title: 'Is there any discount offered to customers?',
                    description: 'We have a reward points system for our customers. For every order placed on a Lifely\
                    store, a customer will get 5% points, which can be redeemed in the next purchase.'
                },
                {
                    title: 'How can we approach the authorized person to share our problem regarding your product, or to\
                    give suggestions/feedback?',
                    description: 'We have a Grievance Management System in place where you can share your problems,\
                    suggestions and feedback, which directly reach the authorized person.'
                }
            ]
        }
    }
}