import HomeOptions from '../../components/HomeOptions'
// import contentData from '../../services/Content-Data'
import router from '@/router'
export default {
    components: {
        HomeOptions
    },
    data() {
        return {
            english: true,
            hindi: false,
            gujarati: false,
            homeLink: false
        }
    },
    computed: {
        'user': function () {
            return this.$store.state.user;
        }
    },
    created() {
        if ('user' in this.$store.state
            && this.$store.state.user != null
            && 'user_type' in this.$store.state.user
            && this.$store.state.user.user_type != "super_admin") {
            router.push({
                name: 'dashboard-home'
            });
        }
    },
    methods: {
        changeToEnglish() {
            this.english = true;
            this.hindi = false;
            this.gujarati = false;
        },
        changeToHindi() {
            this.hindi = true;
            this.english = false;
            this.gujarati = false;
        },
        changeToGujarati() {
            this.gujarati = true;
            this.english = false;
            this.hindi = false;
        }
    },
    mounted() {

        // contentData.getFaqs({
        //     "_locale": "en-IN"
        // }).then((response) => {
        //     console.log(response);
        // });
        window.init_template();

    }
}